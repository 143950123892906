import { NODE_API_HTTP } from '@/common/constants'
import { axiosInstance } from '@/utils/axios'
import camelcaseKeys from 'camelcase-keys'

class UserService {
    constructor() {
        this.nodeApi = `${NODE_API_HTTP}/api/v1/users`
    }

    async getUser(userId) {
        const url = `${this.nodeApi}/${userId}`
        let user = {}
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            user = camelcaseKeys(data, { deep: true })
        } catch(error) {
            console.log('error getting user', error)
        }
        return user
    }

    async checkUserEmailIsNew(email) {
        const url = `${this.nodeApi}/check?email=${email}`
        try {
            const response = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            return { status: true }
        } catch(e) {
            // if we get here, the server has had an issue
            switch (e?.response?.status) {
                case 409:
                    return { status: false, message: 'Email already in use' }
                default:
                    console.error(e)
                // default:
                //     return { status: false, message: 'There is an issue with the email' }
            }
            //TODO: maybe return a message saying this is an unexpected error
            return { status: false, message: 'Unexpected error, the server may be down' }
        }
    }
}

export const userService = new UserService()
