<template>
    <v-form ref="form" v-model="isValid" @submit.prevent="formSubmitHandler">
        <div class="">
            <!--
            <div class="uppercase text-sm text-grey-2 font-semibold">
                Start for free
            </div>-->
            <h1 class="mt-2 mb-3 text-3xl">Create new account</h1>
            <div class="font-display text-grey-2">
                Already have an account?
                <router-link :to="{ name: 'Login' }">
                    <span class="hover:text-primary">Login.</span>
                </router-link>
            </div>
        </div>
        <div class="mt-7 grid grid-cols-2 gap-x-4">
            <div class="col-span-1">
                <v-text-field
                    v-model="signupForm.firstName"
                    label="First name"
                    :rules="rules"
                    filled
                    dense
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    v-model="signupForm.lastName"
                    label="Last name"
                    :rules="rules"
                    filled
                    dense
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    v-model="signupForm.practiceName"
                    label="Practice name"
                    :rules="rules"
                    filled
                    dense
                ></v-text-field>
            </div>
            <div class="col-span-1 relative">
                <v-text-field
                    v-model="signupForm.email"
                    label="Email"
                    type="email"
                    :rules="emailRules"
                    filled
                    dense
                    @change="checkEmail"
                ></v-text-field>
                <div class="block absolute h-full right-0 top-0">
                    <div class="pt-2 pr-4 float-right">
<!--                        <fa-icon icon="check" class="text-green" />-->
                        <fa-icon v-if="checkingEmail !== null" :icon="emailCheckIcon" :spin="emailCheckIcon === 'spinner'" :class="emailIconClass" />
                    </div>
                </div>
                <div v-if="checkEmailMessage" class="w-full -mt-5 text-red pl-3">
                    {{ checkEmailMessage }}
                </div>
            </div>
            <div class="col-span-1 mb-5 flex flex-row justify-between">
                <label id="toggle-label" class="mr-3">Are you accredited by QPA?</label>
                <div>
                    <Toggle
                        labelledby="toggle-label"
                        v-model="isQpaPractice"
                        on-label="Yes"
                        off-label="No"
                    />
                </div>
            </div>
            <div v-if="isQpaPractice" class="col-span-1 col-start-1 flex flex-row justify-between">
                <v-text-field
                    v-model="signupForm.shortName"
                    label="Practice shortname"
                    :rules="isQpaPractice ? rules : []"
                    filled
                    dense
                    @keyup="checkShortname"
                ></v-text-field>
                <fa-icon
                    v-if="checkShortnameLoading || checkShortnameMessage"
                    :icon="checkShortnameIcon"
                    :spin="checkShortnameIcon === 'spinner'"
                    class="ml-2 text-white text-lg mt-2 mr-1"
                    :class="checkShortnameIcon === 'spinner' ? 'text-grey-2' : checkShortnameMessage === 'success' ? 'text-green' : 'text-red'"
                />
            </div>
            <div v-if="isQpaPractice" class="col-span-2 col-start-1 flex flex-col mb-6">
                <div class="flex-row">
                    <span class="text-xs font-semibold mr-5 mt-3">If you don't know your shortname please contact QPA using the button below, otherwise your account will not work with QPA resources.</span>
                </div>
                <div class="flex-row pt-2">
                    <v-btn
                        class="px-3 text-white w-auto"
                        type="button"
                        depressed
                        color="primary"
                        raised
                        large
                        @click.prevent.stop="sendEmail"
                    >
                        Contact Us
                    </v-btn>
                </div>
            </div>
            <div class="col-span-2 mb-5">
                <h2>Select plan</h2>
                <SubscriptionOptions :previous-line-items="previousLineItems" />
            </div>
            <!-- NOTE: hiding for now -->
            <div class="col-span-2 mb-5">
                <div v-if="isLite">
                    <h2>No hardware option</h2>
                    <div class="mb-3">Hardware options are only available for Premium & Enterprise plans. Upgrade to Premium plan for hardware options.</div>
                </div>
                <div v-else>
                    <h2>Select hardware option</h2>
                    <div>
                        All hardware packs are supplied subject to acceptance of the HealthConex
                        <a :href="hardwareAgreementLink" target="_blank">Hardware Agreement</a>.
                        Need help choosing? <a :href="hardwareOptionsLink" target="_blank">Click here</a>.
                    </div>
                    <HardwareOptions :previous-line-items="previousLineItems" />
                </div>
            </div>
            <div class="col-span-2 mb-5 total-cost">
                <div v-for="line of lineItems">
                    <div
                        v-if="(isHardware(line) && !isLite) || isSubLine(line)"
                        class="total-cost-line flex items-center justify-between p-4 border-b-2"
                    >
                        <div>
                            {{ getPreNameText(line)}} {{ line.name }}
                            <span v-if="isSubscription(line)" class="ml-1s">({{ getRecurrenceText(line) }})</span>
                        </div>
                        <div>${{ getLinePrice(line) }}</div>
                    </div>
                </div>
                <div class="total-cost-line  flex items-center justify-between p-4 border-b-2">
                    <h2>Total (includes GST)</h2>
                    <h2>{{ totalCost }}</h2>
                </div>
            </div>
            <div v-if="priceId && subscriptionOption" class="col-span-2 h-0">
                <StripeCheckoutWrapper
                    :submit-subscription="submitToStripe"
                    :previous-line-items="previousLineItems"
                    :signup-session-id="signupSessionId"
                />
            </div>
<!--            <div class="col-span-2">-->
<!--                <v-alert-->
<!--                    v-if="showAlert"-->
<!--                    :type="type"-->
<!--                    class="text-sm mx-5 mt-8 mb-0"-->
<!--                >{{ alertMessage }}</v-alert>-->
<!--            </div>-->
            <div class="col-span-2 flex items-center mt-2">
                <v-checkbox
                    v-model="signupForm.authorisedToActOnBehalfOf"
                    hide-details="true"
                    color="white"
                    :ripple="false"
                    :rules="rules"
                    class="mt-0 pt-0"
                >
                    <template slot="label">
                            I am authorised to act for and on behalf of the above practice, and
                    </template>
                </v-checkbox>
            </div>
            <div class="col-span-2 flex items-center mt-2">
                <v-checkbox
                    v-model="signupForm.acceptedTermsAndConditions"
                    hide-details="true"
                    color="white"
                    :ripple="false"
                    :rules="rules"
                    class="mt-0 pt-0"
                >
                    <template slot="label">
                            I have read and accept the
                    </template>
                </v-checkbox>
                <a href="https://www.ultimateq.health/terms-of-service" target="_blank" class="text-sm ml-1 text-white font-semibold">
                    Terms of Service
                </a>
            </div>
            <div class="col-span-2 mt-2">
                <v-checkbox
                    v-model="signupForm.surveyToolsFeatureAvailable"
                    hide-details="true"
                    color="white"
                    :ripple="false"
                    :rules="rules"
                    class="mt-0 pt-0"
                >
                    <template slot="label">
                        Yes, please make the Survey Tools feature available so I can create a short/long form survey for accreditation.
                    </template>
                </v-checkbox>
            </div>
            <div class="col-span-2">
                <v-alert
                    v-if="showAlert"
                    :type="type"
                    class="text-sm mx-5 mt-8 mb-0"
                >{{ alertMessage }}</v-alert>
            </div>
            <div class="col-span-1">
                <v-btn
                    :disabled="creatingAccount"
                    class="mt-6 px-12 text-white w-auto"
                    type="submit"
                    depressed
                    color="primary"
                    raised
                    large
                >
                    <fa-icon
                        v-if="creatingAccount"
                        icon="spinner"
                        spin
                        class="mr-2 text-white"
                    />
                    {{ btnText }}
                </v-btn>
            </div>
        </div>
    </v-form>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import Toggle from '@vueform/toggle/dist/toggle.vue2.js'
import { emailValidator, required, } from '@/modules/formRules'
import {
    HARDWARE_AGREEMENT_LINK,
    HARDWARE_OPTIONS_LINK,
    PRACTICE_TYPES,
    ULTIMATEQ_CONTACT_PAGE
} from '@/common/constants'
import SubscriptionOptions from '@/components/subscription/SubscriptionOptions.vue'
import StripeCheckoutWrapper from '@/components/stripe/StripeCheckoutWrapper.vue'
import HardwareOptions from '@/components/subscription/HardwareOptions.vue'
import { getRecurrenceText } from '@/components/subscription/partials/subscription.helpers'
import { useSubscriptionOptions } from '@/composables/useSubscriptionOptions'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { storeToRefs } from 'pinia'
import { SubscriptionType } from '@/common/enums/subscription.type'
import { userService } from '@/services/user.service'
import { tenantService } from '@/services/tenant.service'


export default {
    name: 'SignupWithRedirectForm',
    components: { StripeCheckoutWrapper, SubscriptionOptions, HardwareOptions, Toggle },
    setup(_, { root }) {
        const route = root.$route
        const rules = ref([required])
        const emailRules = ref([required, emailValidator])
        const type = ref('')
        const practiceTypes = ref([...PRACTICE_TYPES])
        const isQpaPractice = ref('')
        // this is a tri-state, true-false-null, so we can show different things
        const checkingEmail = ref(null)
        const emailCheckIcon = ref('')
        const checkEmailMessage = ref('')
        const checkEmailTimeout = ref(null)
        const signupSessionStore = useSignupSessionStore()
        const { signupSessionSub: subscription, signupSessionLoading, signupSessionErrorMessage } = storeToRefs(signupSessionStore)
        const hardwareAgreementLink = ref(HARDWARE_AGREEMENT_LINK)
        const hardwareOptionsLink = ref(HARDWARE_OPTIONS_LINK)
        const {
            isValid,
            subscriptionOption,
            showAlert,
            form,
            alertMessage,
            previousLineItems,
            signupSessionId,
            signupForm,
            submitToStripe,
            priceId,
            creatingAccount,
            lineItems,
            btnText,
            formSubmitHandler,
            manuallySetShortName,
        } = useSubscriptionOptions({signupSessionId: route?.query?.sid})
        const isLite = computed(() => subscription.value?.option === SubscriptionType.LitePlan)
        const emailIconClass = computed(() => {
            if (emailCheckIcon.value === 'check') return 'text-green'
            if (emailCheckIcon.value === 'ban') return 'text-red'
            return 'text-white'
        })

        const totalCost = computed(() => {
            let sum = lineItems.value.reduce((acc, item) => {
                if (isHardware(item) && isLite.value) return acc
                return acc += Number(item?.price?.formatted_value || 0)
            }, 0)
            return `$${sum}`
        })

        const isHardware = (item) => {
            return getPreNameText(item) === 'Hardware option:'
        }
        const isSubLine = (item) => {
            return getPreNameText(item) === 'Plan:'
        }

        // function to return if it's the hardware or the subscription
        const getPreNameText = (item) => {
            return item?.metadata?.subscription ? 'Plan:' : 'Hardware option:'
        }

        const isSubscription = (line) => {
            return !!line?.metadata?.subscription
        }

        const getLinePrice = (line) => line?.price?.formatted_value

        const hasCheckErrored = computed(() => checkShortnameMessage.value === 'error' || checkEmailMessage.value === 'error')

        const checkShortnameTimeout = ref(null)
        const checkShortnameMessage = ref('')
        const checkShortnameLoading = ref(false)
        const checkShortnameIcon = computed(() => checkShortnameLoading.value ? 'spinner' : checkShortnameMessage.value === 'success' ? 'check' : 'ban')
        const checkShortname = () => {
            const shortName = signupForm.value?.shortName
            if (!shortName) {
                manuallySetShortName.value = false
                return
            }
            checkShortnameLoading.value = true
            // we don't need to set a timeout if we don't have a shortname so let's return for now
            if (checkShortnameTimeout.value) clearTimeout(checkShortnameTimeout.value)
            checkShortnameTimeout.value = setTimeout(async () => {
                try {
                    const exists = await tenantService.tenantExists(shortName)
                    checkShortnameMessage.value = exists ? 'success' : 'error'
                } catch (e) {
                    checkShortnameMessage.value = 'error'
                } finally {
                    checkShortnameLoading.value = false
                    manuallySetShortName.value = true
                }

            }, 2000)
        }

        const checkEmail = () => {
            checkingEmail.value = false
            emailCheckIcon.value = ''
            if (checkEmailTimeout.value) clearTimeout(checkEmailTimeout.value)

            // we don't need to set a timeout if we don't have an email so let's return for now
            // or the email isn't valid, so we return
            if (!signupForm.value?.email || !emailValidator(signupForm.value?.email)) return
            checkingEmail.value = true
            emailCheckIcon.value = 'spinner'
            checkEmailTimeout.value = setTimeout(async () => {
                const resp = await userService.checkUserEmailIsNew(signupForm.value?.email)
                checkingEmail.value = false
                emailCheckIcon.value = resp.status ? 'check' : 'ban'
                checkEmailMessage.value = resp.message || ''

                // setTimeout(() => {
                //     checkingEmail.value = null
                //     emailCheckIcon.value = ''
                // }, 2000)
            }, 2000)
        }

        const sendEmail = () => {
            window.open(`mailto:miriam.crane@healthconex.com.au?cc=connah@healthconex.com.au,feedback@ultimateq.health&subject=Request for shortname for ${signupForm.value.practiceName}&body=Hello, I would like to request a practice shortname for my practice.`, '_self')
            // window.location.href = `mailto:miriam.crane@healthconex.com.au?cc=connah@healthconex.com.au,feedback@ultimateq.health&subject=Request for shortname for ${signupForm.value.practiceName}&body=Hello, I would like to request a practice shortname for my practice.`
        }

        watch(signupSessionLoading, (newValue) => {
            creatingAccount.value = newValue
            submitToStripe.value = newValue
        })

        watch(signupSessionErrorMessage, (newValue) => {
            alertMessage.value = newValue
            showAlert.value = true
            type.value = 'error'
        })

        return {
            isQpaPractice,
            checkShortnameMessage,
            totalCost,
            signupForm,
            rules,
            emailRules,
            isValid,
            showAlert,
            alertMessage,
            type,
            practiceTypes,
            btnText,
            creatingAccount,
            priceId,
            subscriptionOption,
            submitToStripe,
            lineItems,
            previousLineItems,
            signupSessionId,
            form,
            isLite,
            hardwareAgreementLink,
            hardwareOptionsLink,
            checkingEmail,
            emailCheckIcon,
            emailIconClass,
            checkEmailMessage,
            checkShortnameIcon,
            checkShortnameLoading,
            hasCheckErrored,
            checkShortname,
            checkEmail,
            getPreNameText,
            formSubmitHandler,
            getRecurrenceText,
            isSubscription,
            isHardware,
            isSubLine,
            sendEmail,
            getLinePrice
        }
    },
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>