<template>
    <div class="w-full h-full flex h-screen grid grid-cols-12">
        <div
            class="h-full col-span-12 md:col-span-4 lg:col-span-3 text-base bg-navy-midlight relative"
        >
            <div class="w-full h-full flex h-screen grid grid-cols-12 fixed">
                <div
                    class="col-span-12 md:col-span-4 lg:col-span-3"
                    :style="sideBannerBgStyle"
                >
                    <div class="signupSidebanner text-white max-w-3/4 m-auto">
                        <div class="">
                            <img
                                src="@/assets/images/ultimateq-tenant-logo.png"
                                alt="logo"
                                class="logo"
                            />
                            <div
                                class="text-sm text-center text-base leading-4 mt-4"
                            >
                                <div class="my-2">
                                    Online
                                    <span
                                        class="text-primary"
                                    >patient feedback.</span>
                                </div>
                                <div>Real time. Every time.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="h-full col-span-12 md:col-span-8 lg:col-span-9 relative flex flex-col"
        >
            <div class="flex items-center h-full w-full">
                <div class="py-8 px-4 w-full lg:w-4/5 xl:w-3/5 m-auto">
                    <SignUpWithRedirectForm />
                </div>
            </div>
            <GlobalFooter class="w-full h-auto" />
        </div>
    </div>
</template>

<script>
import GlobalFooter from '@/components/shared/GlobalFooter'
import SignupSideBannerBg from '@/assets/images/signupsidebanner-bg.jpg'
import { ref } from '@vue/composition-api'
import SignUpWithRedirectForm from '@/components/SignUpWithRedirectForm'

export default {
    name: 'Signup',
    components: {
        GlobalFooter,
        SignUpWithRedirectForm,
    },
    setup() {
        const sideBannerBgStyle = ref({
            backgroundImage: `url('${SignupSideBannerBg}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        })

        return { sideBannerBgStyle }
    },
}
</script>
